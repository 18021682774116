.DashboardContainer {
    padding : 5px 3px
}

.DashboardHeader {
    display: flex; 
    justify-content: space-between; 
    flex-direction: row;
    margin: auto 0px;
}

.DashboardTabDropDown {
    width : 50%; 
    display: flex; 
    justify-content: end;
    margin-right: 3px;
}
.DashboardTabDropDown > div {
    width: 30%; 
    margin-left: 10px;
}

.DatePickerContainer {
    display:flex; 
    margin : 15px auto; 
    margin-right : 2px;
    justify-content:space-between; 
    width : 31%;
}

.Container {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 1.25rem;
    font-family: Arial, Helvetica, sans-serif;
}

.CardContainer {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 20px;
}
.Card {
    height : 100px;
    border-radius: 10px;
    padding: 15px;
    box-shadow:0 0 0.5152rem rgba(0, 0, 0, 0.281);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.CardHeading {
    color : rgba(97, 94, 97,1);
    font-size: 1.3rem;
    font-weight: bolder;
}

.CardTitle {
    font-weight: 200;
}

.GraphContainer {
    margin-top: 30px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.Graph {
    height: 70vh;
    border-radius: 6px;
    width: 99%;
    padding: 15px;
    box-shadow:0 0 0.6152rem rgba(0, 0, 0, 0.281);
}

.TableContainer {
    margin-top: 20px;
}

@media screen and (max-width:1100px){
    
}
@media screen and (max-width : 960) {
}
@media only screen and (max-width: 768px) {
    .CardContainer {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .DatePickerContainer {
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }
    .DashboardTabDropDown > div {
        width: 40%;
    }
}

@media screen and (max-width:600px){
    .GraphContainer {
        grid-template-columns: 1fr;
    }
    .CardContainer {
        grid-template-columns: 1fr 1fr;
    }
    .DatePickerContainer {
        flex-direction: row;
        justify-content: space-between;
        margin: 10px 0px;
        width: 100%;
        margin-bottom: 20px;
    }
    .DashboardHeader {
        flex-direction: column;
    }
    .DashboardTabDropDown {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }
    .DashboardTabDropDown > div {
        width: 49%;
        margin: 10px 0px;
    }
}