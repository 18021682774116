thead{
    font-size:12px
}

.btn-grp{
    display: flex;
}
.search-wrapper{
    padding:0px
}

@media only screen and (max-width: 768px) {
    .saveDiv{
        display: flex;
        flex-direction: column-reverse;
        gap: 15px;
        font-size: 10px;
        justify-content: center;
        align-items: center;
    }
    .responsive{
        flex-direction: column;
        gap: 15px;
        justify-content: center;
        align-items: center;
    }
    .import {
        width: 100%;
    }
    .btn-grp{
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
    

}