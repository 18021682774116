.modal {
    position: fixed;
    top: 5%;
    right: 25%;
    bottom: 8%;
    left: 25%;
    box-shadow: 0 10px 30px 0 rgba(127, 127, 127, 0.3);
    background: #fff;
    border-radius: 10px;
    z-index: 1000;
}

.exportReportDialog {
    position: fixed;
    top:11%;
    right: 30%;
    bottom: 20%;
    left: 30%;
    box-shadow: 0 10px 30px 0 rgba(127, 127, 127, 0.3);
    background: #fff;
    border-radius: 10px;
    z-index: 1000;
}


.variantContent{
    margin-left: 15px;
    font-size: 15px;
}

.variantPrice {
    font-size:13px;
    font-weight:600;
    text-align: end;
}

.fontSizeDisplay {
    font-size:13px;
    display: flex;
}

.drapDown {
    position: absolute;
    box-shadow: 0 10px 30px 0 rgba(127, 127, 127, 0.3);
    background: #fff;
    border-radius: 10px;
    z-index: 1000;
    width: 100%;
}

.OptionType:hover{
    background-color: #ced4da;
}

.Box{
    border-bottom: 1px solid #ced4da;
}

.BoxContent{
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    padding: 20px 25px;
}
.Border{
    box-shadow: 0 5px 5px -5px #000000;
}
.Footer{
    display: flex;
    justify-content: space-between;
    padding: 20px;
    /* position: fixed; */
    bottom: 7%;
    /* width: 50%; */
    border-top: 1px solid #d2d5d8;
    box-shadow: 0 -5px 5px -5px #000000;
}
.SaveCustomerBox{
    display: flex;
    justify-content: space-between;
    padding: 20px;
    bottom: 7%;
    width: 50%;
    border-top: 1px solid #d2d5d8;
    box-shadow: 0 -5px 5px -5px #000000;
}

.DialogBtnBox {
    display: flex;
    justify-content: flex-end;
    bottom: 16%;
    position: fixed;
    box-shadow: none;
    padding: 20px;
    width: 50%;
    border-top: 0px;
}

.reportExportDialog {
    display: flex;
    justify-content: flex-end;
    bottom: 20%;
    position: fixed;
    box-shadow: none;
    border-top: 0;
    width: 40%;
    padding: 20px;
}

.ConfirmBtnBox {
    display: flex;
    justify-content: flex-end;
    bottom: 18%;
    position: fixed;
    box-shadow: none;
    padding: 20px;
    width: 30%;
    border-top: 0px;
}

.DialogFieldBox {
    padding: 20px 20px 0px 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.Button button{
    background: #fafbfb;
    border: 1px solid #d2d5d8;
    padding: 5px 15px;
    border-radius: 4px;
    color: #8c9196;
}

.TaxRatePopup {
    padding: 10px 20px;
    font-size: 14px;
    overflow-y: auto;
    height: 64%;
}

.TaxRatesPopupFlexBetween {
    display: flex;
    justify-content: space-between;
}

.TaxRatesPopupCommonStyles {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.FooterBox button {
    background: #fafbfb;
    border: 1px solid #d2d5d8;
    padding: 5px 15px;
    border-radius: 4px;
    color: #8c9196;
}

.RemoveGstNoFooterButton {
    background-color: #006E52;
    color: #fff;
    font-size: 15px;
    padding: 6px 15px;
}

.TextAlignRight{
    display: flex;
    justify-content: space-between;
}
.Add div{
    margin: 0px 5px; 
}

.Search{
    display: flex;
    border:1px solid #ced4da;
    padding:0px 10px; 
    border-radius:4px;
}
.Boxes{
    padding: 1.1rem;
    align-items: center;
    border-bottom: 1px solid #c9cccf;
    display: flex;
    /* justify-content: space-between; */
}

.checkBox {
    border: 2px solid #8c9196;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    border-radius: 4px;
}

.checkBox:checked {
    appearance: auto;
    border-radius: 2px solid #8c9196;
    clip-path: circle(65% at 50% 50%);
}

.BoxContainer{
    height: 61.5%;
    overflow-y: scroll;
}
input[type=checkbox]{
    width: 18px;
    height: 18px;
    margin-top: 5px;
}
.Boxes:hover{
    background-color: #F6F6F7;
}
.Child{
    padding: 0.6rem 1rem;
    align-items: center;
    border-bottom: 1px solid #c9cccf;
    display: flex;
    padding-left: 3rem;
    justify-content: space-between;
}
.Child:hover{
    background-color: #F6F6F7;
}

.SearchTag:focus{
    outline: none;
}
.highlightText {
    font-weight:600;
}
.AddressType{
    width: fit-content;
    margin: auto;
    background-color: lightgreen;
    padding: 0px 10px;
    border-radius: 10px;
}

.RateBox{
    display: flex;
    margin-top: 10px;
    padding:0px 20px;
    font-size: 14px;
}

.Outline:focus{
    outline: none !important;
}

.ClosePopUpIcon {
    font-size: 25px;
    color: rgb(129, 138, 155);
    cursor: pointer;
}

.DeleteAddressBtn {
    font-size:15px;
    margin-right:30px;
    /* color: #2c6ecb; */
    display: none;
    padding: 4px;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid black;
}

.SearchIcon {
    font-size: 15px;
    padding: 11px 8px 3px;
    color: rgb(129, 138, 155);
}

.SearchInputBox {
    width: 100%;
    display: flex;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.variantSkuBox {
    margin: 0px 15px;
    display: flex;
    font-size:13px;
}

.variantSku {
    margin-right:5px;
    font-weight:600;
}

.ErrorMessage{
    padding: 20px;
    font-size: 14px;
    text-align: center;
    background-color: #F8D7DA;
    border-radius: 4px;
}
.Title{
    color: #FF6200;
    margin-top: 2px;
    margin-left: 2px;
}
.Input{
    width: 100%;
    margin-top: 5px;
    padding: 5px;
    border: 1px solid #C2C5C9;
    border-radius:4px;
}

.PhoneContentBox{
    display: flex;
    border:  1px solid rgb(194, 197, 201);
    border-radius: 4px;
    margin-top: 5px;
}

.PhoneCountry{
    padding: 5px;
    border-right: 1px solid rgb(194, 197, 201);
}

.PhoneInput{
    width: 100%;
    padding: 5px;
    font-size: 15px;
}

.PhoneBox:focus{
    outline: black solid 2px !important;
}

.PhoneErrorMsg{
    display: none;
    color: #e73431;
}

.GstInput{
    width: 100%;
    margin-top: 5px;
    padding: 5px;
    font-size: 15px;
    border: 1px solid #C2C5C9;
    border-radius:4px;
}

.SaveCustomer{
    background-color:#006E52;
    border:0;
    color: #FFF;
    font-size:15px;
    border-radius: 4px;
    padding: 6px 10px;
}

.UpdateSalesUserOfCustomer{
    border:0;
    font-size:15px;
    border-radius: 4px;
    padding: 6px 0px;
    background-color: transparent;
    color: #1976D2;
    font-weight: 600;
    margin: 0px 10px;
}

.selectGstNoMsg {
    color: #fff;
    background:red;
    padding: 0px 10px;
    border-radius: 8px;
    width: fit-content;
    display: none;
}

.selectedAddress {
    background: #008060;
    border: 0px;
    padding: 5px 15px;
    border-radius: 4px;
    color: #fff;
}

.selectAddress {
    background: #fafbfb;
    border: 1px solid #d2d5d8;
    padding: 5px 15px;
    border-radius: 4px;
    color: #000;
}

.flexBetween {
    display: flex;
    justify-content: space-between;
}

.AddressContainer {
    width: 70%;
}

.ContentBox{
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
}

.productContent{
    font-size: 15px;
}

.productImage{
    width: 35px;
    height: 35px;
    margin: 0px 15px;
    border: 1px solid #ced4da;
    border-radius: 4px;
}

.contactPopUp{
    bottom: 12%;
    top: 14%;
}

.AddressPopUp{
    padding: 10px 20px;
    height: 460px;
    overflow-y:auto;
    font-size: 15px;
}

.AddressInput{
    width: 100%;
    margin-top: 5px;
    padding: 5px;
    border: 1px solid #C2C5C9;
    border-radius: 4px;
}

.shippingAddressPhoneInput{
    display:flex;
    border: 1px solid rgb(194, 197, 201);
    border-radius: 4px;
    margin-top: 5px;
}

.billingAddressPhoneInput{
    display: flex;
    border: 1px solid rgb(194, 197, 201);
    border-radius: 4px;
    margin-top: 5px;
}

.shippingAddressPhoneCountry{
    padding: 5px;
    border-right: 1px solid rgb(194, 197, 201);
}

.billingAddressPhoneCountry{
    padding: 5px;
    border-right:1px solid rgb(194, 197, 201);
}

.shippingAddressPopUpBtn{
    background-color: #006E52;
    color: #FFF;
    padding: 6px 10px;
    border: 0;
    border-radius: 4px;
}

.RequiredInput{
    color: #FF6200;
    margin-top: 2px;
    margin-left: 2px;
}


.phoneInput{
    width: 100%;
    padding: 5px;
    font-size: 15px;
    border: 0px;
}

.IsValidGst {
    font-size: 14px;
    background-color: red;
    color: white;
    padding: 0px 5px;
    margin-top: 6px;
    border-radius: 4px;
    width: fit-content;
    display: none;
}

.addNewGstNo {
    width: 100%;
    margin-top: 5px;
    padding: 5px;
    border: 1px solid #C2C5C9;
    border-radius: 4px;
}

.CancelButton {
    background-color:#C1C1C1;
    border: 0px;
    border-radius: 4px;
    color: #fff;
    font-size: 20px;
    padding: 6px;
    width: 120px;
}

.XMark {
    font-size: 75px;
    color: rgb(211, 47, 47);
    cursor: pointer;
}

.confirmModal {
    position: fixed;
    top: 5%;
    right: 35%;
    bottom: 8%;
    left: 35%;
    box-shadow: 0 10px 30px 0 rgba(127, 127, 127, 0.3);
    background: #fff;
    border-radius: 10px;
    z-index: 1000;
}

.Button{
    display: flex;
    justify-content: space-between;
    padding: 15px;
    margin: 0px auto;
    width: 50%;
}

.FooterBox {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #d2d5d8;
    padding: 15px 20px;
    width: 100%;
}


.DiscountInput {
    width: 100%;
    margin-top: 5px;
    padding: 5px;
    border: 1px solid #C2C5C9;
    border-radius: 4px;
}

.ApplyButton {
    background-color: #006E52;
    color: #fff;
    font-size: 15px;
    padding: 6px 15px;
}

.ShippingInput {
    width: 99%;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #b0aaaa;
}

.TaxRateCloseButton {
    background-color: transparent;
    color: #000;
    font-size: 15px;
    padding: 6px 15px;
}

.DiscountRestrictMsg {
    display: none;
    width: fit-content;
    background-color: red;
    padding: 0px 5px;
    border-radius: 5px;
    margin: 5px 0px;
    color: #fff;
    font-size: 15px;
}

.confirmPopUpCommonStyles {
    color: black;
    text-align: center;
}

.contactInputBox {
    width: 100%;
    display: flex;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem;
}

.searchField{
    width: 98%;
    padding: 0.375rem 0.75rem 0.375rem 4px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-clip: padding-box;
    appearance: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border: 0;
    background-color: transparent;
}
.searchField:focus{
    outline: none;
}

.SelectedCustomers {
    display: flex;
    flex-wrap: wrap;
}

.SelectedCustomers li {
    list-style: none;
    background-color: #EBEBEB;
    padding: 1px 5px 1px 8px;
    border-radius: 10px;
    margin: 2px 4px;
    font-size: 14px;
}

.orderAllocateDetail {
    background-color: lavender;
    padding: 5px;
    text-align: center;
    font-weight: 600;
}

.Warning {
    display: none !important;
}

.LineItem:last-child {
    border-bottom: none !important;
    border-radius: 0px 0px 10px 10px !important;
}

@media screen and (max-width:1085px) {
    .modal, .exportReportDialog{
        right: 20%;
        left: 20%;
    }

    .SaveCustomerBox {
        width: 60%;
    }

    .DialogBtnBox, .reportExportDialog {
        width: 60%;
    }

    .AddressContainer {
        width: 65%;
    }    
}

@media screen and (max-width:1000px) {
    .confirmModal{
        right: 20%;
        left: 20%;
    }
    .ConfirmBtnBox {
        width: 60%;
    }

    .modal, .exportReportDialog{
        right: 15%;
        left: 15%;
    }
    .SaveCustomerBox {
        width: 70%;
    }
    .DialogBtnBox, .reportExportDialog {
        width: 70%;
    }
}
@media screen and (max-width:900px) {
    .modal, .exportReportDialog{
        right: 10%;
        left: 10%;
    }
    .ConfirmBtnBox {
        width: 70%;
    }
    .SaveCustomerBox {
        width: 80%;
    }
    .DialogBtnBox, .reportExportDialog {
        width: 80%;
    }
    .confirmModal{
        right: 15%;
        left: 15%;
    }
}

@media screen and (max-width:750px) {
    .modal, .exportReportDialog{
        right: 5% !important;
        left: 5% !important;
    }
    /* .ConfirmBtnBox {
        width: 75%;
    } */
    .SaveCustomerBox {
        width: 90% !important;
    }
    .DialogBtnBox, .reportExportDialog {
        width: 90% !important;
    }
}


@media screen and (max-width:600px) {
    .modal, .exportReportDialog{
        right: 2% !important;
        left: 2% !important;
    }
    .ConfirmBtnBox {
        width: 80%;
    }
    .SaveCustomerBox {
        width: 95% !important;
    }
    .DialogBtnBox, .reportExportDialog {
        width: 95% !important;
    }
    .Child{
        padding-left: 2rem;
    }
    .variantContent, .productContent{
        font-size: 14px;
    }
    .productImage{
        margin: 0px 10px;
        width: 35px;
        height: 35px;
    }
    input[type=checkbox] {
        width: 16px;
        height: 16px;
    }
    .contactPopUp{
        bottom: 14%;
        top: 14%;
        font-size: 14px;
    }
    .AddressPopUp{
        font-size: 14px;
    }

    .shippingAddressPopUp{
        font-size: 14px;
    }

    .GstNO{
        font-size: 15px;
    }
    .confirmModal{
        right: 10%;
        left: 10%;
    }

    .AddressContainer {
        width: 55%;
    }
    
}

.SearchLoader {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid #000000;
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 0.4s linear infinite;
  }
  
@keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
} 