/* .output{
    width: 100%;
    height: 100%;
} */

.imageBox{
    display: flex;
    flex-direction: row;
}
.crossIcon {
    width: 30px;
    height: 30px;
    position: absolute;
    margin-top: -20px;
    cursor: pointer;
    margin-left: -10px;
}

.imageDiv {
    position: relative;
    width: 243px;
    height: 230px;
    border: 1px solid lightgray;
    border-radius: 6px;
    padding: 20px;
    display: inline-block;
    margin: 0px 5px 10px;
}

.smallImageDiv {
    position: relative;
    width: 130px;
    height: 125px;
    border: 1px solid lightgray;
    border-radius: 6px;
    padding: 20px;
    display: inline-block;
    margin: 0px 5px 10px;
}