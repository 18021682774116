.Container{
    background: #fff;
    border-radius: 10px;
}

.HeaderBox{
    display:flex;
    justify-content:space-between;
    /* flex-wrap: wrap; */
    padding: 20px 0px;
}

.SearchBox{
    display: flex;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    height: 40px;
    margin-bottom: 0.3em;
    margin-right: 1em;
}

.CreatedByFilter{
    position: relative;
    width: 28%;
}