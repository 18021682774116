.nav {
  @media (max-width: 900px) {
    display: none !important;
  }
}

.DynamicDash {
  @media (max-width: 900px) {
    width: 100% !important;
  }
}

.DynamicDash {
  width:calc(100% - 240px);
  transition: all 0.3s ease-in-out;
}

.NavCollapsed {
  width:calc(100% - 70px);
  transition: all 0.3s ease-in-out;
}

.navToggle {
  position: absolute;
  overflow: hidden;
  transition: 0.5s all;
  display: none !important;
  background-color: #00000055;
  z-index: 999;
  top: 0;
  width: 100%;
  height: 100%;
  left: -100%;
  @media (max-width: 900px) {
    display: block !important;
  }
}

.show {
  left: 0 !important;
}

.link {
  background-color: #dddddd56;
  color: #333;
  padding: .25rem;
  border-radius: .5rem;
  margin: .25rem 0;
  font-size: 1rem;
  transition: all 0.25s;

  &:hover {
    background-color: #ff620066;
    color: #eee;
  }

  &.active {
    background-color: #ff6200;
    color: #fff;
  }
}

.Item:active {
  font-weight: 550;
}

.Item:hover {
  color: rgb(255, 98, 0);
}

.Icon:hover {
  color: rgb(255, 98, 0);
}