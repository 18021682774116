.modal {
    position: fixed;
    top: 5%;
    right: 25%;
    bottom: 8%;
    left: 25%;
    box-shadow: 0 10px 30px 0 rgba(127, 127, 127, 0.3);
    background: #fff;
    border-radius: 10px;
    z-index: 1000;
}

.deleteModal {
    position: fixed;
    top: 28%;
    right: 35%;
    bottom: 25%;
    left: 35%;
    box-shadow: 0 10px 30px 0 rgba(127, 127, 127, 0.3);
    background: #fff;
    border-radius: 10px;
    z-index: 1000;
}

.Box{
    border-bottom: 1px solid #ced4da;
}

.BoxContent{
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    padding: 20px 25px;
}

.Border{
    box-shadow: 0 5px 5px -5px #000000;
}

.Footer{
    display: flex;
    justify-content: space-between;
    padding: 20px;
    padding: 20px;
    position: fixed;
    bottom: 7%;
    width: 50%;
    border-top: 1px solid #d2d5d8;
    box-shadow: 0 -5px 5px -5px #000000;;
}

.Button button{
    background: #fafbfb;
    border: 1px solid #d2d5d8;
    padding: 5px 15px;
    border-radius: 4px;
    color: #8c9196;
}

.TextAlignRight{
    display: flex;
    justify-content: space-between;
}

.Add div{
    margin: 0px 5px; 
}

.Search{
    display: flex;
    border:1px solid #ced4da;
    padding:0px 10px; 
    border-radius:4px;
}

.Boxes{
    padding: 1.1rem;
    align-items: center;
    border-bottom: 1px solid #c9cccf;
    display: flex;
}

.BoxContainer{
    height: 61.5%;
    overflow-y: auto;
}

input[type=checkbox]{
    width: 18px;
    height: 18px;
    margin-top: 5px;
}

.Boxes:hover{
    background-color: #F6F6F7;
}

.Child{
    padding: 0.8rem;
    align-items: center;
    border-bottom: 1px solid #c9cccf;
    display: flex;
    padding-left: 3rem;
    justify-content: space-between;
}

.SearchTag {
    border: 0;
    margin-top: 4px;
    width: 100%;
}

.Child:hover{
    background-color: #F6F6F7;
}

.SearchTag:focus{
    outline: none;
}

.l2Collections{
    display: none;
    margin-left: 12px;
}

.l3Collection{
    display: none;
    margin-left: 12px;
}

.TagContainer {
    padding: 30px 20px;
    overflow-y: scroll;
    height: 400px;
}

.TagContainerBox {
    width: 72%;
    display: flex;
    border: 1px solid #ced4da;
    border-radius:  0.25rem;
    padding: 2px 4px 4px 4px;
}

.FaRightLeftIcon {
    font-size: 15px;
    padding: 11px 8px 3px;
    rotate: 90deg;
    margin-top: 5px;
}

.FrequentlyUsedBtn {
    border: 0;
    padding: 0;
    background-color: transparent;
    font-weight: 600;
    font-size: 14px;
}

.AvailableBox {
    padding: 20px 10px;
    font-size: 13px;
}
.Tag {
    display: flex;
    margin-top: 10px;
}

.ButtonBox {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #d2d5d8;
    padding: 15px 20px;
}

.FaMagnifyingGlass {
    font-size: 15px;
    padding: 11px 8px 3px;
    color: rgb(129, 138, 155);
}

.DoneButton {
    background-color: #006E52;
    color: #fff;
    font-size: 15px;
    padding: 6px 15px;
    border: 0;
    border-radius:4px;
}

.DeleteProductPopupBtn {
    border: 0px;
    color: #fff;
    border-radius: 4px;
    padding: 0px 10px;
}