.mainContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.containBoxes{
    width: 70%;
}

.container{
    margin: 0 auto;
    max-width: 100%;
}

.Box{
    width: 100%;
    border-radius: 8px;
    margin-bottom: 1.25rem;
    box-shadow:0 0 0.3152rem rgba(0, 0, 0, 0.181), 0 0 0.3152rem rgba(0, 0, 0, 0.181);  
}
.containerBox{
    padding: 1.1rem;
}

.searchField{
    width: 98%;
    padding: 0.375rem 0.75rem 0.375rem 4px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-clip: padding-box;
    appearance: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border: 0;
    background-color: transparent;
}
.searchField:focus{
    outline: none;
}
.submitButton{
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 1.1rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, 
    background-color 0.15s ease-in-out, 
    border-color 0.15s ease-in-out, 
    box-shadow 0.15s ease-in-out;
    border: 1px solid #ced4da;
}
.fontSize{
    font-size: 14px;
}

.commonTextWidth p{
    width: 30%;
}
.commonTextStyle{
    text-align: end;
}
.containerFooter{
    border-top: 1px solid #ced4da; 
    font-size:14px; 
    padding: 1rem 1.1rem;
}

.asideContainer{
    width: 28%;
}
.Background{
    width: 100%;
}

.Table{
    border-collapse: collapse;
}

.Thead{
    border-bottom: 1px solid;
    border-bottom-color: #DBDDE0 !important;
    display: flex;
}

.containProduct{
    display: flex;
    padding: 15px 10px;
    border-bottom: 1px solid #DBDDE0 !important;
}

.productDetail{
    width: 50%;
    padding: 10px;
    display: flex;
}

.asideBoxHeader {
    margin: auto 0px;
    font-size: 12px;
    font-weight: 600;
}

.gstBox {
    padding: 0px 1.1rem;
    border-top: 1px solid #DBDDE0;
}

.gstBoxHeader {
    margin: 5px 0px;
    font-size: 12px;
    font-weight: 600;
}

.gstBoxNumber {
    color: #2C6ECB;
    font-size: 13px;
    padding-bottom:5px;
}

.searchContainer {
    width: 100%;
    display: flex;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem;
}

.searchIcon {
    font-size: 15px;
    padding: 11px 8px 3px;
    color: rgb(129, 138, 155);
}

.variantContainer {
    font-size: 14px;
    padding: 0px;
}

.variantProductHeader {
    width: 49%;
    padding: 10px 20px;
}

.variantDiscountHeader {
    width: 10%;
    padding: 10px 0px;
}

.variantQuantityHeader{
    width: 12%;
    padding: 10px 0px;
    /* padding-left: 95px; */
    margin-left: 10px;
    padding-left: 20px;
}

.QtyPCSHighlight {
    margin-left: 5px;
    background-color: rgb(255, 98, 0);
    color: rgb(255, 255, 255);
    font-size: 12px !important;
    /* padding: px 5px; */
    border-radius: 5px;
    height: 18px;
    width: 50px;
    margin: auto;
}

.variantSubTotalHeader {
    padding: 10px 0px 10px 10px;
    width: 11%;;
}

.variantTotalHeader {
    padding: 10px;
    width: 15%;
}

.SubTotal {
    display: none;
}

@media screen and (max-width: 1115px){
    .rateFontSize{
        font-size: 13px;
    }
}

@media screen and (max-width:1085px) {
    .productDetail{
        width: 68%;
    }
}

@media screen and (max-width: 1044px){
    .productDetail {
        width: 52%;
    }
    .containBoxes{
        width: 100%;
    }
    .asideContainer{
        width: 100%;
    }
}

.variantImage {
    width: 40px;
    height: 40px;
    border: 1px solid #ced4da;
    border-radius: 4px;
}

.variantMrp {
    color: rgb(109, 113, 117);
    text-decoration: line-through;
    margin-left: 3px;
}

.responsiveVariantMrp {
    display:flex;
    color: #0d6efd;
    text-decoration: line-through;
}


.Input::-webkit-inner-spin-button, 
.Input::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    /* appearance: none; */
    margin: 0; 

}
.Input:focus{
    outline: none;
}
.Qty{
    width: 14%;
    display: flex;
    border: 1px solid #DBDDE0;
    height: 44px;
    border-radius: 4px;
}

.IncBtn{
    background: #E4E5E7;
    margin: 2px;
    padding: 0px 4px;
    border-radius: 2px;
    height: 18px;
    cursor: pointer;
}
.IncQtyBtn{
    width: 12px;
	vertical-align : text-top !important; 
}
.DecBtn{
    background: #E4E5E7;
    margin: 2px;
    padding: 0px 4px; 
    border-radius: 2px;
    height: 18px;
    padding-top: 3px;
    cursor: pointer;
}
.DecQtyBtn{
    width: 12px;
    transform: rotate(180deg);
    vertical-align: top !important;
}

select{
    width: 100%;
    padding: 5px 5px;
    border-color: #ced4da;
    border-radius: 4px;
    margin-top: 5px;
}

select:focus{
    border-color: #0d6efd;
    outline: none;
}

.MrpLessThanRestrict{
    font-size: 13px;
    color: #fff;
    display: none;
}

.MrpRestrictMessage{
    width: fit-content;
    background-color: red;
    padding: 0px 5px;
    border-radius: 5px;
    margin-bottom: 5px;
}

.DiscountRestrictMsg {
    width: fit-content;
    background-color: red;
    padding: 0px 5px;
    border-radius: 5px;
    margin: 5px 0px;
    color: #fff;
}

.QtyNegativeMsg {
    display: none;
    width: fit-content;
    background-color: red;
    padding: 0px 5px;
    border-radius: 5px;
    margin-bottom: 5px;
}

.GstRate {
    display: flex;
}

.DiscountMsg {
    display: none;
}

.variantDiscountInclGst {
    color:  rgb(109, 113, 117);
	margin-right: 9px;
}

.variantDiscountInclGstInput {
    width: 60px;
    border: 1px solid rgb(180 185 189);
    border-radius: 0.25rem;
}

.variantDiscountTitle {
    margin-left: 10px;
    font-size: 13px;
}

.bulkPackagingType {
    display: flex;
    width: 55px;
    margin-top: 15px;
    font-size: 13px;
}

.bulkPackagingValue {
    margin-left:5px;
    background-color: rgb(255, 98, 0);
    color: rgb(255, 255, 255);
    font-size:12px !important;
    height: 22px;
    padding: 1px 5px;
    border-radius: 5px;
    width: 58px;
    display: flex;
    justify-content: center;
}

.bulkPackagingQtyBox {
    display: flex;
    border: 1px solid #DBDDE0;
    height: 36px;
    border-radius: 4px;
}

.bulkPackagingQtyInput {
    width: 100%;
    padding: 5px 0px 5px 15px;
    font-size: 16px;
    border: 0px;
}

.bulkPackagingIncQty {
    background: #E4E5E7;
    margin: 2px;
    padding: 0px 4px;
    border-radius: 2px;
    height: 14px;
    cursor: pointer;
}

.bulkPackagingDecQty {
    background: #E4E5E7;
    margin: 2px;
    padding: 0px 4px;
    border-radius: 2px;
    height: 14px;
    /* padding-top: 3px; */
    cursor: pointer;
}

.variantQuantityInput {
    width: 100%;
    padding: 10px 0px 5px 15px;
    font-size: 16px;
    border: 0px;
}

.variantDiscountBox {
    width: 13%;
    padding: 10px 0px;
    font-size: 14px;
    margin-right: 10px;
}

.createOrderQuantity {
    /* display: none; */
    width: 18%;
    text-align: center;
    margin-top: 12px;
    padding-left: 5px;
    margin-top: 9px;
}

.responsiveExclGst {
    font-size: 12px;
	display: none;
}

.variantProductTotal {
    padding: 10px 12px;
    width: 80px;
}

.removeProductInContainer{
    font-size: 16px;
    color: rgb(129, 138, 155);
    cursor: pointer;
    margin: 12px 0px 0px 14px;
}

.customerDetail {
    color: #2c6ecb;
    display: flex;
}

.Span{
    border: 0px !important;
    padding: 5px;
    outline: none;
    border: ridge 2px;
    border-radius: 4px;
    min-height: 3em;
    overflow: auto;

}

.Span:focus{
    outline: 2px solid #458fff
}

.appliedDiscountType{
    font-size: 12px;
    background-color: #93d62dcc;
    border-radius: 4px 4px 4px 4px;
    padding: 0px 4px;
    width: fit-content;
    margin: auto;
}

.createOrderCompleteOrderButton{
    border: 0px;
    padding: 7px 16px;
    background-color: #6c943eeb;
    color: #fff;
    border-radius: 5px;
    font-weight: 600;
    letter-spacing: 1px;
}

.createOrderSendPIButton{
    border: 0px;
    padding: 7px 16px;
    background-color: #6c943eeb;
    color: #fff;
    border-radius: 5px;
    font-weight: 600;
    letter-spacing: 1px;
}

.updateOrderButton{
    border: 0px;
    padding: 7px 16px;
    background-color: #008060;
    color: #fff;
    border-radius: 5px;
    font-weight: 600;
    letter-spacing: 1px;
}

.minPadding{
    padding: 0.5rem 1.1rem;
}
.verticalCustomerBox{
    width:33%;
}

.customerVertical{
    display: none;
}
.verticalCustomerBoxWidth{
    width: 50%;
}
.ShowResponsive{
    display: none;
}
.hideResponsiveRow{
    display: none;
}

.variantDetail{
    margin-left: 10px;
}

.customerBox {
    display: flex;
	justify-content: space-between;
    margin-bottom: 1.5rem;
}

.xMarkIcon {
    font-size: 17px;
    color: rgb(129, 138, 155);
    cursor: pointer;
    margin-top: 7px;
}

.customerDetailBox {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
}

.customerName {
    color:  #2c6ecb;
	display: flex;
}

.commonMargin {
    margin-right: 5px;
}

.commonDisplay {
    display: flex;
}

.commonDisplayContent {
    display: flex;
    justify-content: space-between;
}

.editDiscount {
    color: #0d6efd;
    cursor: pointer;
    width: 100%;
}

.overAllDiscount {
    color: #000;
    cursor: pointer;
    text-align: end;
}

.shippingBox {
    margin-bottom: 1rem;
    color: #777;
}

.shippingContent {
    width : 100%;
    display: flex;
    justify-content: space-between;
}

.editShipping {
    color: #0d6efd;
    cursor: pointer;
    width: 50%;
}

.shippingPrice {
    width: 50%;
    text-align: end;
    color: black;
}

.billBox {
    display: flex;
}

.variantTotal {
    margin-left: 6px;
}

.responsiveTotal{
    color: rgb(109, 113, 117);
}

.InvoiceTypes {
    display: flex;
    justify-content: end;
}

.customerRole {
    font-size: 13px;
    padding: 3px 8px;
    background-color: greenyellow;
    border-radius: 4px;
}

.textMutedContent {
    display: flex;
    justify-content: space-between;
    color: #777;
    margin-bottom:1rem;
}

.contactInformation {
    margin: auto 0px;
    font-size: 12px;
    font-weight: 600;
}

.contactEditBtn {
    color: #2c6ecb;
    cursor: pointer;
    font-size: 14px;
}

.printGstBox {
    display: none;
}

.taxRates {
    color: #0d6efd;
    cursor: pointer;
}

.taxAmount {
    text-align: end;
    color: #000;
}

.Total {
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
}

.draftOrderButton {
    border: 0px;
    padding: 7px 16px;
    color: #fff;
    border-radius: 5px;
    font-weight: 600;
    letter-spacing: 1px;
}

.buttonBox {
    display: flex;
    justify-content: space-between;
}

.contactInputBox {
    width: 100%;
    display: flex;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem;
}

.contactSearchIcon {
    font-size: 15px;
    padding: 11px 8px 3px;
    color: rgb(129, 138, 155);
}

.selectAddressBtn {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 14px;
    color: #2c6ecb;
    cursor: pointer;
}

.AddNewAddressBtn {
    color: #2c6ecb;
    cursor: pointer;
    font-size: 14px;
    margin-top: 10px;
}

.QuantityProductErrorMsg {
    display: none;
    width: fit-content;
    background: red;
    color: #fff;
    margin: 10px 0px 0px;
    border-radius: 5px;
    padding: 0px 9px 1px;
}

.addNewGstNo {
    color: #2C6ECB;
    text-decoration: none;
    padding-right: 0px;
    cursor: pointer;
    font-size: 14px;
}

.flexBetween {
    display: flex;
	justify-content: space-between;
}

.flexDirectionColumn {
    display: flex;
	flex-direction: column;
}

.shippingAddressHeader {
    margin: auto 0px;
    font-size: 12px;
    font-weight: 600;
}

.colorFontSize {
    color: #2c6ecb;
	font-size: 14px;
}

.deliveryRemark {
    width: 100%; 
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.remarkHeader {
    margin: auto 0px;
	padding: 6px;
}

.TagsBtn {
    color: #2C6ECB;
    text-decoration: none;
    padding-right: 0px;
    cursor: pointer;
}

.addTagBox {
    display: flex;
    justify-content: end;
    margin: 2px 0px;
}

.addTagButton {
    border: 2px solid grey;
    padding: 0px 6px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 13px;
}

.discount {
    color: #0d6efd;
    cursor: pointer;
    width: 100%;
    display: none;
}

.TagsInput {
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.selectedTagsBox {
    display: flex;
    flex-wrap: wrap;
    font-size: 13px;
    margin-top: 5px;
}

.selectedTags {
    background-color: #E4E5E7;
    padding: 2px 10px;
    margin: 3px 3px;
    border-radius: 4px;
}

.notExistProductErrorMsg {
    color: #fff;
    background:red;
    padding: 0px 10px;
    border-radius: 8px;
    width: fit-content;
}

.TaxRatePrintFlexBetween {
    display: flex;
    justify-content: space-between;
}

.UpdateSalesDialog {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1300;
    position: fixed;
    top: 0%;
    width: 100%;
    left: 0%;
    bottom: 0%;
}

@media screen and (max-width:650px) {
    .Thead{
        display: none;
    }
    .productDetail {
        width: 96%;
        padding: 5px;
    }
    .hideDiscountResponsive{
        display: none;
    }
    .hideQtyResponsive{
        display: none;
    }
    .hideTotalResponsive{
        display: none;
    }
    .ShowResponsive{
        border-top: 1px solid #DBDDE0;
        display: block;
    }
    .marginTop{
        margin-top: -12px;
    }
    .hideResponsiveRow{
        display: block;
    }
    .variantDetail{
        margin-left: 10px;
    }
    .hidePcsResponsive {
        display: none !important;
    }
    .SubTotal {
        display:flex !important;
    }
}

@media screen and (max-width:400px) {
    .variantDiscountInclGst {
        margin-right: 2px;
    }
}