.CreateCommission {
  .Flex {
    display: flex;
    align-items: start;
    // flex-direction: row-reverse;
    gap: 1rem;

    @media (max-width: 1000px) {
      flex-direction: column-reverse;
      align-items: stretch;
    }
  }

  .Button {
    border: none;
    outline: none;
    border-radius: 8px;
    background-color: green;
    color: white;
    height: 40px;
    width: 120px;

    &:disabled {
      opacity: 0.5;
    }
  }

  .Box {
    // width: 100%;
    border-radius: 8px;
    margin-bottom: 1.25rem;
    box-shadow: 0 0 0.3152rem rgba(0, 0, 0, 0.181),
      0 0 0.3152rem rgba(0, 0, 0, 0.181);
    padding: 1rem 1rem;
    margin-top: 1rem;
  }

  .Box2 {
    display: flex;
    align-items: center;
    gap: 2rem;
    flex-wrap: wrap;
  }

  .Order {
    h6 {
      margin-bottom: 1rem;
    }

    .OrderTextSearch {
      display: flex;
      align-items: center;
      gap: 1rem;
      border: 1px solid #ced4da !important;
      border-radius: 0.25rem;
      padding: 0.3rem 0.75rem;

      div {
        flex-grow: 1;
      }

      input {
        outline: none;
        border: none;
        padding: 0px;
        width: 100%;

        &:focus {
          outline: none;
        }
      }
    }
  }

  .Modal {
    position: fixed;
    z-index: 1000;
    top: 5%;
    right: 25%;
    bottom: 8%;
    left: 25%;
    // width: 90%;
    // max-width: 600px;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 0 0.3152rem rgba(0, 0, 0, 0.181),
      0 0 0.3152rem rgba(0, 0, 0, 0.181);
    padding: 1rem 0rem;

    .Head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 1rem;
    }

    .OrderTextSearch {
      display: flex;
      align-items: center;
      gap: 1rem;
      border: 1px solid #ced4da !important;
      border-radius: 0.25rem;
      padding: 0.3rem 0.75rem;
      margin: 1rem;

      div {
        flex-grow: 1;
      }

      input {
        outline: none;
        border: none;
        padding: 0px;
        width: 100%;

        &:focus {
          outline: none;
        }
      }
    }

    .Orders {
      overflow-y: auto;
    }
  }
}

.Modal {
  position: fixed;
  z-index: 1000;
  top: 5%;
  right: 25%;
  bottom: 8%;
  left: 25%;
  // width: 90%;
  // max-width: 600px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 0 0.3152rem rgba(0, 0, 0, 0.181),
    0 0 0.3152rem rgba(0, 0, 0, 0.181);
  padding: 1rem 0rem;

  .Head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
  }

  .OrderTextSearch {
    display: flex;
    align-items: center;
    gap: 1rem;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem;
    padding: 0.3rem 0.75rem;
    margin: 1rem;

    div {
      flex-grow: 1;
    }

    input {
      outline: none;
      border: none;
      padding: 0px;
      width: 100%;

      &:focus {
        outline: none;
      }
    }
  }

  .Orders {
    overflow-y: auto;
  }
}
